<template>
  <div>
    <div class="row" v-if="information.length">
      <div class="col-md-6 mt-2" v-for="(item, key) in information" :key="key">
        <router-link :to="{ name: 'information-notification-slug',params: { slug: item.slug } }"
                     title="Tin nổi bật Edutalk">
          <img :src="item.image" alt="item.image" class="w-100" style="border-radius: 5px">
        </router-link>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-sm-6 col-xl-4">
        <div class="bg-warning-400 rounded overflow-hidden position-relative text-white mb-g">
          <div class="">
            <h3 class="display-4 d-block l-h-n m-0 fw-500 p-3">
              {{ user.accountType }}
              <div class="d-block w-100">
                <small class="m-0 l-h-n">Vị trí hiện tại</small>
                <small v-if="is_ctv" class="m-0 l-h-n mt-1" style="font-size: 18px;">Cộng tác viên</small>
              </div>
              
            </h3>
            
          </div>
          <i class="fal fa-gem position-absolute pos-right pos-bottom opacity-15 mb-5 mr-3"
             style="font-size: 5rem;"></i>
          <router-link :to="{name: 'profile'}" class="small-box-footer">Xem thêm <i
              class="fas fa-arrow-circle-right"></i></router-link>
        </div>

      </div>
      <div class="col-sm-6 col-xl-4" v-if="showInfoUser">
        <div class="bg-primary-300 rounded overflow-hidden position-relative text-white mb-g">
          <div class=""> 
            <h3 class="display-4 d-block l-h-n m-0 fw-500 p-3">
              {{ user.countCustomer }}
              <small class="m-0 l-h-n">Khách hàng trong tháng</small>
            </h3>
          </div>
          <i class="fal fa-user position-absolute pos-right pos-bottom opacity-15 mb-5 mr-3" style="font-size:5rem"></i>
          <router-link :to="{name: 'customer-list'}" class="small-box-footer detail-customer">Xem thêm <i
              class="fas fa-arrow-circle-right"></i></router-link>
        </div>
      </div>
      <div class="col-sm-6 col-xl-4" v-if="showInfoUser">
        <div class="bg-success-200 rounded overflow-hidden position-relative text-white mb-g">
          <div class="">
            <h3 class="display-4 d-block l-h-n m-0 fw-500 p-3">
              {{ user.salesTeamCurrent | formatVND }}
              <small class="m-0 l-h-n">Tổng doanh số trong tháng</small>
            </h3>
          </div>
          <i class="fal fa-lightbulb position-absolute pos-right pos-bottom opacity-15 mb-5 mr-3"
             style="font-size: 5rem;"></i>
          <router-link :to="{name: 'kinh-doanh-doi'}" class="small-box-footer">Xem thêm <i
              class="fas fa-arrow-circle-right"></i></router-link>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top:30px;">
      <div class="col-sm-6 col-xl-4" v-if="showSalesTeam">
        <div class="card border">
          <h5 class="card-header">Tiến độ đạt chỉ tiêu doanh số đội</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <vue-apex-charts type="donut" :options="chartOptions" :series="seriesSalesTeam"></vue-apex-charts>
                <div class="text-chart mt-1">
                  <h5 class="card-title form-label"><i class="fa fa-flag" style="color: red;"></i> Chỉ tiêu:
                    {{ salesTeam.target | formatVND }}</h5>
                  <p class="card-text" style="font-size: 14px">Đã đạt: {{ salesTeam.achieved | formatVND }}</p>
                  <p class="card-text" style="font-size: 14px" v-if="salesTeam.needMore > 0">Cần thêm:
                    {{ salesTeam.needMore | formatVND }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-4" v-if="showSalesPersonal">
        <div class="card border">
          <h5 class="card-header">Tiến độ đạt chỉ tiêu doanh số cá nhân</h5>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <vue-apex-charts type="donut" :options="chartOptions" :series="seriesSalesPersonal"></vue-apex-charts>
                <div class="text-chart mt-1">
                  <h5 class="card-title form-label"><i class="fa fa-flag" style="color: red;"></i> Chỉ tiêu:
                    {{ salesPersonal.target | formatVND }}</h5>
                  <p class="card-text" style="font-size: 14px">Đã đạt: {{ salesPersonal.achieved | formatVND }}</p>
                  <p class="card-text" style="font-size: 14px" v-if="salesPersonal.needMore > 0">Cần thêm:
                    {{ salesPersonal.needMore | formatVND }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="contract.id">
      <el-dialog @closed="dialogTableVisible = false" title="Thông báo" :visible.sync="dialogTableVisible" class="responsive">
        <p>Bạn cần cập nhật lại hợp đồng <span class="bold">{{ contract.contract ? contract.contract.id : '' }}</span> do voucher <span class="bold">{{ contract.promotion ? contract.promotion.name : '' }}</span> đã được sử dụng hết.</p>
        <p>Vui lòng cập nhật lại hợp đồng.</p>
        <div class="d-flex justify-content-end">
          <el-button @click="dialogTableVisible = false">Để sau</el-button>
          <el-button type="primary" @click="updateContract">
            Cập nhập ngay
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import '@fullcalendar/core/vdom'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {mapGetters, mapState} from "vuex";
import {USER_DASHBOARD} from "../../core/services/store/profile.module";
import VueApexCharts from 'vue-apexcharts';
import {GET_CONTRACT_DELETE_VOUCHER, INFORMATION_INFORMATION} from "@/core/services/store/service.module";
import {
  SALE_LEADER,
  SALE_EXPERT,
  SALE_MEMBER,
  SALE_INTERN,
  DOANH_SO_VI_TRI_MOI_SI,
  DOANH_SO_VI_TRI_MOI_S,
  DOANH_SO_VI_TRI_MOI_SE,
  TARGET_PERSONAL,
  TARGET_TEAM,
  TINH_OBJECT_C,
  TINH_C, getTargets
} from "../../core/config/accountTypeOption";
import {TRUNG_TAM, TINH} from "../../core/config/area";
import {JUNIOR} from "@/core/config/businessLevel";

export default {
  name: "dashboard",
  components: {
    VueApexCharts,
  },
    metaInfo: {
        meta: [
            { property: 'og:url', content: 'https://noibo.edutalk.edu.vn' },
            { property: 'og:type', content: 'website' },
            { property: 'og:title', content: 'Dashboard' },
            { property: 'og:description', content: 'Website nội bộ Edutalk' },
            { property: 'og:image', content: 'https://noibo.edutalk.edu.vn/media/birthday/logo_birthday_2023.png' },
        ],
    },
  data() {
    return {
      dialogTableVisible: true,
      seriesNewPosition: [],
      seriesSalesTeam: [],
      seriesSalesPersonal: [],
      chartOptions: {
        colors: ['#472F92', '#FDB913'],
        labels: ['Đã đạt', 'Cần thêm'],
        chart: {
          type: 'donut',
          height: 100
        },

        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10
          }
        },
        grid: {
          padding: {
            bottom: -80
          }
        },
        responsive: [{
          breakpoint: 375,
          options: {
            chart: {},
            legend: {
              position: 'bottom',
              offsetX: -30,
              offsetY: -30,
            }
          }
        },
          {
            breakpoint: 426,
            options: {
              chart: {},
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: -20,
                offsetY: -60,
              }
            }
          },
          {
            breakpoint: 769,
            options: {
              chart: {},
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: -10,
                offsetY: -60,
              }
            }
          },
          {
            breakpoint: 1025,
            options: {
              chart: {},
              legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: -10,
                offsetY: -140,
              }
            }
          }],
      },
      user: {
        accountType: '',
        countCustomer: 0,
        salesTeamCurrent: 0,
      },
      newPosition: {
        target: 0,
        achieved: 0,
        needMore: 0
      },
      salesPersonal: {
        target: 0,
        achieved: 0,
        needMore: 0
      },
      salesTeam: {
        target: 0,
        achieved: 0,
        needMore: 0
      },
      showNewPosition: false,
      showSalesTeam: false,
      showSalesPersonal: false,
      showInfoUser: false,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth'
      },
      information: [],
      contract: {},
      publicPath: process.env.VUE_APP_BASE_URL,
      showMessageSale: false,
      showMessageBackOffice: false,
      srcImageNewYear: '',
      is_ctv: false,
    }
  },
  created() {
    this.getSpecialInformation();
  },
  mounted() {
    // document.getElementById("overlay").style.display = "block";
    // if (['sale-leader', 'sale-expert', 'sale-member', 'sale-intern', 'gdvp', 'gdkv', 'giam-doc-vung'].includes(this.currentUser.account_type.slug)) {
    //   this.showMessageSale = true
    // } else {
    //   this.showMessageBackOffice = true
    // }
    if (this.currentUser.profile.candidate && this.currentUser.profile?.candidate.status == 3 && this.currentUser.account_type.slug !== 'sale-intern' && this.currentUser.is_confirm != 2) {
      this.$router.push({path: '/profile'});
    }
    this.is_ctv = this.currentUser.profile.is_collaborators;
    this.user.accountType = this.currentUser && this.currentUser.account_type ? this.currentUser.account_type.name : '';
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Trang Dashboard", icon: 'far fa-home'}]);
    this.getTarget()
    this.getInfoCurrentUser();
    this.getTargetNewPosition();
    this.checkShow();
    this.getContractDeleteVoucher();
  },
  methods: {
    getInfoCurrentUser() {
      this.$store.dispatch(USER_DASHBOARD).then((response) => {
        if (response.status === 422) {
          this.$notify({
            title: 'Warning',
            message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
            type: 'warning'
          });
        } else {
          let accountTypeId = this.currentUser && this.currentUser.account_type ? this.currentUser.account_type.id : '';
          this.user.countCustomer = response.data.countCustomer ?? 0;
          this.user.salesTeamCurrent = response.data.salesTeamCurrent ?? 0;
          if (accountTypeId != SALE_INTERN) {
            this.newPosition.achieved = response.data.salesTeamCurrent + response.data.salesTeamLog;
          } else {
            this.newPosition.achieved = response.data.salesTeamCurrent;
          }
          this.newPosition.needMore = this.newPosition.target - this.newPosition.achieved;
          this.seriesNewPosition.push(this.newPosition.achieved);
          this.seriesNewPosition.push(this.newPosition.needMore);
          // doanh so doi
          this.salesTeam.achieved = response.data.salesTeamCurrent;
          this.salesTeam.needMore = this.salesTeam.target - this.salesTeam.achieved;
          this.seriesSalesTeam.push(this.salesTeam.achieved);
          this.seriesSalesTeam.push(this.salesTeam.needMore);
          // doanh so ca nhan
          this.salesPersonal.achieved = response.data.salesPersonal;
          this.salesPersonal.needMore = this.salesPersonal.target - this.salesPersonal.achieved;
          this.seriesSalesPersonal.push(this.salesPersonal.achieved);
          this.seriesSalesPersonal.push(this.salesPersonal.needMore);
        }
      });
    },
    getTargetNewPosition() {
      let accountTypeId = this.currentUser && this.currentUser.account_type ? this.currentUser.account_type.id : '';
      if (accountTypeId == SALE_EXPERT) {
        this.newPosition.target = DOANH_SO_VI_TRI_MOI_SE;
      }
      if (accountTypeId == SALE_MEMBER) {
        this.newPosition.target = DOANH_SO_VI_TRI_MOI_S;
      }
      if (accountTypeId == SALE_INTERN) {
        this.newPosition.target = DOANH_SO_VI_TRI_MOI_SI;
      }
    },
    getTarget() {
      let accountTypeId = this.currentUser && this.currentUser.account_type ? this.currentUser.account_type.id : '';
      if (accountTypeId === SALE_LEADER) {
        this.salesTeam.target = this.getTargetSalesTeam();
      } else {
        this.salesPersonal.target = this.getTargetPersonal();
      }
    },
    getTargetPersonal() {
      let businessLevelIndex = this.currentUser && this.currentUser.profile && this.currentUser.profile.business_level ? this.currentUser.profile.business_level.index : '';
      let salaryMechanismId = this.currentUser ? this.currentUser.profile.salary_mechanism_id : '';
      let area_type_id = this.currentUser && this.currentUser.profile && this.currentUser.profile.branch && this.currentUser.profile.branch.area ? this.currentUser.profile.branch.area.type_id : '';
      let account_type_id = this.currentUser && this.currentUser.profile ? this.currentUser.profile.account_type_id : '';
      if (account_type_id === SALE_INTERN) {
        businessLevelIndex = JUNIOR; // Nếu là SI thì sẽ giống Sale Member JUNIOR
      }
      if ((!area_type_id || !businessLevelIndex) && account_type_id !== SALE_INTERN) {
        this.showSalesPersonal = false;
        return;
      }
      if (area_type_id === TRUNG_TAM) {
        return TARGET_PERSONAL[TRUNG_TAM][businessLevelIndex];
      }
        return TARGET_PERSONAL[TINH][salaryMechanismId][businessLevelIndex];
    },
    getTargetSalesTeam() {
      let area_type_id = this.currentUser && this.currentUser.profile && this.currentUser.profile.branch && this.currentUser.profile.branch.area ? this.currentUser.profile.branch.area.type_id : '';
      let businessLevelIndex = this.currentUser && this.currentUser.profile && this.currentUser.profile.business_level ? this.currentUser.profile.business_level.index : '';

      if (!businessLevelIndex) {
        this.showSalesTeam = false;
      }

      if (area_type_id === TRUNG_TAM) {
        return TARGET_TEAM[TRUNG_TAM][businessLevelIndex];
      } else {
        return TARGET_TEAM[TINH][businessLevelIndex];
      }
    },
    checkShow() {
      let accountTypeId = this.currentUser && this.currentUser.account_type ? this.currentUser.account_type.id : '';
      if (accountTypeId == SALE_INTERN || accountTypeId == SALE_MEMBER || accountTypeId == SALE_EXPERT) {
        this.showNewPosition = true;
      }
      if (accountTypeId == SALE_EXPERT || accountTypeId == SALE_LEADER) {
        this.showSalesTeam = true;
      }
      if (accountTypeId == SALE_INTERN || accountTypeId == SALE_MEMBER || accountTypeId == SALE_EXPERT) {
        this.showSalesPersonal = true;
        this.showInfoUser = true;
      }
    },
    getSpecialInformation() {
      this.$store.dispatch(INFORMATION_INFORMATION, {is_special: 1})
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.information = response.data;
            }
          })
    },
    getContractDeleteVoucher() {
      this.currentUser.user_positions.filter((value) => {
        if (value.position === 'TVV') {
          this.$store.dispatch(GET_CONTRACT_DELETE_VOUCHER, value.user_id)
              .then((response) => {
                this.contract = response.data;
              });
        }
      })
    },
    updateContract() {
      if (this.contract.is_product_edutalk) {
        this.$router.push({ name: 'contract-edit-edutalk', params: { contract_id: this.contract?.contract?.id }, query : {customer_id : this.contract?.contract?.customer_id} })
      } else {
          this.$router.push({ name: 'contract-edit-partner', params: { contract_id: this.contract?.contract?.id }, query : {customer_id : this.contract?.contract?.customer_id} })
      }
    },
    // off() {
    //   document.getElementById("overlay").style.display = "none";
    // },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(['currentUser'])
  },
};
</script>
